
import { createContext, useState } from 'react';

//create a context, with createContext api
export const modalContext = createContext();

const ModalContextProvider = (props) => {
        // this state will be shared with all components 
    const [modalInfo, setModalInfo] = useState({show: false, title: null, content: null, confirm: true});

    return (
                // this is the provider providing state
        <modalContext.Provider value={[modalInfo, setModalInfo]}>
            {props.children}
        </modalContext.Provider>
    );
};

export default ModalContextProvider;