import React, { useState, useEffect, useContext } from 'react'
import { Form, Input, Button, Space, Checkbox, Radio } from 'antd';
import { RiUser3Line, RiLockPasswordLine } from 'react-icons/ri';
import {MdAlternateEmail} from 'react-icons/md'
import '../styles/Login.css'
import {createElementFromHTML, isUserNameValid} from '../utils/helpers.jsx'
import { modalContext } from '../utils/context';
import {TiDeleteOutline} from 'react-icons/ti'




const DomainUserM = (props) => {
    const [username, setUsername] = useState(null)
    const [msg, setMsg] = useState(null)
    const [userList, setUserList] = useState([])
    const [catchall, setCatchAll] = useState(false)
    const [modalInfo, setModalInfo] = useContext(modalContext);
    const [ui, setUi] = useState([null, null, null])

    const onSetUser = data => {
        if (data.catchall && data.username.length > 0){
            alert('Please choose "Specified Users" if you only want to catch a list of usernames, or remove all users if you want to catch all.')
            return 
        }
        const userListReq = data.catchall || data.username.length==0? '@' : data.username.map( e=> e.username).join('|')
        const [un, pw, dm] = ui
        data = {userlist: userListReq, pwd: pw, user:un, submit: 1, domain:dm}
        const formData = new FormData();
        Object.entries(data).map(([k, v])=>{formData.append(k, v)})
        fetch(
            'https://api.xyamail.com/cgi-bin/domainusers.cgi',
            {
                method: 'POST', 
                body: formData,
            })
                .then(response => {
                    return response.json()
                }).catch(err => {
                    console.log(err)
                }).then( r => {
                    if (r['res'] === 'OK') {
                        const isCatchall = r['userlist'][0][0]=="@"
                        const ulist = r['userlist'].map( e=> {return e.split('\t')[0].split('@')[0]})
                        const elist = r['erroruser'].map( e=> {return e.split('\t')[0].split('@')[0]})
                        setUsername(data['user'])
                        setModalInfo({
                            show: true, 
                            title: null,
                            confirm: true,
                            content: <div style={{margin:'1rem'}}>
                                <div>Username: {username}</div>
                                {isCatchall ?
                                'Catch-all enabled. It may take a few seconds to activate the new rule. Please do not submit repeatedly.'
                                :
                                <>
                                {ulist.length > 0? <div style={{color:'green'}}>Successfully added:  {ulist.join(', ')}</div>:null}
                                {elist.length > 0? <div style={{color:'orange'}}>Failed:  {elist.join(', ')}</div>:null}
                                <>It may take a few seconds to activate the new rule. Please do not submit repeatedly.</>
                                </>
                                }
                            </div>
                        })
                    }
                        

                })
        
    }

    const onFinish = data => {
        setMsg(null)
        setUi([data.user, data.pwd, data.domain])
        data= {...data, submit: 'Submit'}
        const formData = new FormData();
        Object.entries(data).map(([k, v])=>{formData.append(k, v)})
        fetch(
        'https://api.xyamail.com/cgi-bin/catchall.cgi', 
        {
            method: 'POST', 
            body: formData,
        })
            .then(response => {
                return response.json()
            }).catch(err => {
                console.log(err)
            }).then( r => {
                if (r['res'] != 'OK') {
                    setUsername(data['user'])
                    setModalInfo({
                        show: true, 
                        title: null,
                        confirm: true,
                        content: <div style={{margin:'1rem'}}>
                            Username: {username}
                            <div style={{color:'red'}}>{r['desc']}</div>
                        </div>
                    })
                    return 
                }
                console.log(r)
                var userListRes = []
                var catchallRes = true
                if (r['users'][0][0] !== "@") {
                    catchallRes = false
                    userListRes = r['users'].map( e=> {return {username: e.split('\t')[0].split('@')[0]}})
                } 
                setCatchAll(catchallRes)
                setUsername(data['user'])
                setUserList(userListRes)
                setMsg(r)
            })
    };
    
    useEffect(()=>{
        // console.log(username, msg)
        if (username && msg && catchall != null) {
            console.log(catchall,userList)
            setModalInfo({
                show: true, 
                title: null,
                content: <div style={{margin:'1rem'}}>
                <Form
                name="add_user"
                className="login-form-float"
                onFinish={(data)=>{
                    onSetUser(data); 
                }}
                initialValues={{
                    catchall: catchall,
                    username: userList
                  }}
                >
            <a className='form-title'>Domain User Management</a>
            <div className='form-inputs'>
            <>@{ui[2]}</>
            <Form.Item label="" name="catchall">
                <Radio.Group>
                <Radio.Button value={true} style={{color:'green'}}>Catch-all</Radio.Button>
                <Radio.Button value={false} style={{color:'orange'}}>Specified Users</Radio.Button>
                </Radio.Group>
            </Form.Item>
            
            <Form.List name="username" label={ui.domain}>
                {(fields, { add, remove }) => (
                    <>
                        <div className='form-grid'>{fields.map((field) => 
                        (
                            <Space
                            key={field.key}
                            style={{ display: "flex", justifyContent:'center',alignItems:'center'}}
                            >
                            <Form.Item
                                {...field}
                                name={[field.name, "username"]}
                                rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^[a-zA-Z0-9]{3,20}$/),
                                    message: 'Invalid username',
                                }]}
                            >
                                <Input placeholder={field.name} />
                            </Form.Item>
                            <TiDeleteOutline size='1.2rem' color='orange' onClick={() => remove(field.name)} styles={{cursor:'pointer'}}/>
                            </Space>)
                            )}</div>
                        <Button onClick={fields.length>=50? null:() => add()} 
                        style={{marginBlockEnd:'1rem', borderRadius:'1rem', cursor:'pointer'}}>
                            + Add User
                        </Button>
                    </>)}
            </Form.List>
            </div>
            <div style={{fontSize:'0.8rem'}}>Note: username must be between 3 and 20 characters and contain only letters and numbers. Please do not include domain suffix.</div>
            
            <div style={{width:'25rem', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                <Button className="login-form-button" htmlType="submit" >
                    Update
                </Button>
                <Button className="login-form-button" onClick={()=>{setModalInfo({show:null, confirm: true, content: null}); setUi([null, null])}}>
                    Cancel
                </Button>
            </div>
        </Form>
                </div>
            })
        }
    }, [msg])

    return (
        <div className="login-form-container">
        
        <Form
        name="normal_login"
        className="login-form"
        layout="inline"
        method='POST'
        onFinish={(data)=>onFinish(data)}
        >
        <a className='form-title'>Domain User Management</a>
        <div className='form-inputs'>

        <Form.Item
            name="user"
            rules={[
                {
                    required: true,
                    message: 'Please input your username.',
                },
            ]}
        >
        <Input 
        prefix={<RiUser3Line className="site-form-item-icon" />} 
        placeholder="Username (without suffix)" 
        suffix={'@xyamail.com'}
        />

        </Form.Item>
        <Form.Item
            name="pwd"
            rules={[
                {
                    required: true,
                    message: 'Please input your password.',
                },
            ]}
        >
            <Input
                prefix={<RiLockPasswordLine className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
            />
        </Form.Item>

        <Form.Item
        name="domain"
        rules={[
            {
                required: true,
                message: 'Please input your new domain.',
            },
        ]}
        >
        <Input
            prefix={<MdAlternateEmail className="site-form-item-icon"/>}
            // type="password"
            placeholder="Alias Domain"
        />
        </Form.Item>
        
        </div>
        
        <div style={{width:'25rem', display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <Button type="primary" htmlType="submit" className="login-form-button">
                Manage Domain Users
            </Button>
        </div>
    </Form>     
    </div>
    );
};

export default DomainUserM