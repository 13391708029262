import React, { Component } from 'react';
import '../styles/PricingCard.css'

function CardDescription({ title, description }) {	
	return (
		<div className="card-description">
			<h2>{ title }</h2>
			<p>{ description } </p>
		</div>
	);
};

function CardBilling({ price, recurrency }) {
	return (
		<div className="card-billing">
			<p>
				<strong className="price">$ { price }</strong>
			</p>
			<p>
				<span className="recurrency">
					{recurrency}
				</span>
			</p>
		</div>
	);
};

function CardFeatures({ data }) {	
	return (
		<div className="card-features">
			<ul>
				{ 
					data.map((item, index) => {
						return (
							<li key={index} style={{color:item[2]}}><div>&#x2022; {item[0]}</div><div>{item[1]}</div></li>
						)
					})
				}
			</ul>
		</div>
	);
};

function CardAction({ clickMe }) {
	return (
		// <div className="card-action">
			<button className="card-action" onClick={clickMe}>BUY NOW</button>
		// </div>
	);
};

function PricingCard(props) {	
	const { 
    color,
    title,
    description,
    price,
    recurrency,
    mostPopular,
    data,
    clickMe
  } = props;
	
	return (
		<div className={`card pricing-card`} style={{borderColor: color}}>
      { (mostPopular) ? <span className="most-popular">Most Popular</span> : null }
			<CardDescription title={title} description={description} />
			<CardBilling price={price} recurrency={recurrency} />
			<CardFeatures data={data} />
			<CardAction clickMe={clickMe} />
		</div>
	);
};

export default PricingCard;

