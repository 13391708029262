import React, { Component } from 'react';


function FAQItem(props) {
    const data = props.data
    return ( 
        <details>
        <summary>{data.title}</summary>
        <div className='FAQDetails'>{data.details}</div>
        </details>
     );
}

export default FAQItem;