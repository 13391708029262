import React, { Component } from 'react';
import '../styles/Modal.css'
import { modalContext } from '../utils/context';
import { useContext } from 'react';


function Modal(props) {
    const [modalInfo, setModalInfo] = useContext(modalContext);
    // console.log(modalInfo.confirm)
    return ( 
        <div className={modalInfo.show? 'Modal show':'Modal hide'}>
            <div className='Modal-notice'>
                <div className='Modal-title'>{modalInfo.title}</div>
                <div className='Modal-body'>{modalInfo.content}</div>
                {modalInfo.confirm?
                <div className='Modal-button'  onClick={()=>setModalInfo({show: false, title: null, content: null, confirm:true})}>
                Confirm
                </div>
                : null}
            </div>

        </div>
     );
}

export default Modal;