import React, { Component } from 'react';
import {RiMenuFill} from 'react-icons/ri'
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Dropdown.css'


function Dropdown(props) {

    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !(event.target.className == 'Nav-dropdown')) {
            props.setActive(false)
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [wrapperRef]);
    
    return (  
        <div className='Menu' ref={wrapperRef}>
        <RiMenuFill size={'1.5rem'} className={props.active? 'Menu active': 'Menu inactive'}/>
        <div  className={props.active? 'Menu-nav active': 'Menu-nav inactive'}>
        <Link className="Menu-nav-item"  to='/changepwd'>Change Password</Link>
        <Link className="Menu-nav-item"  to='/managedom'>Add Alias Domain</Link>
        <Link className="Menu-nav-item"  to='/listdom'>List Alias Domains</Link>  
        <Link className="Menu-nav-item"  to='/setupdkim'>Setup DKIM</Link>  
        <Link className="Menu-nav-item"  to='/domainuserm'>Domain User Management</Link>  
        <Link className="Menu-nav-item"  to='/purgedom'>Delete Alias Domain</Link>  
        <Link className="Menu-nav-item"  to='/purgeuser'>Delete Account</Link>  
        </div>
        </div>
    );
}

export default Dropdown;