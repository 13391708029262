import React, { Component } from 'react';
import { convertCardViewArray } from '../utils/helpers';
import { cardInfo } from "../data/featureDesc";
import CardView from './CardView';
import '../styles/Features.css'

const infoArray = convertCardViewArray(cardInfo, 3)

function Features() {
    return ( 
        <div className='features'>
        <div className="features-title">
        Powerful Features
        </div>
        <div className="features-cardview">
        {infoArray.map((row, index)=>{
            return <div key={index} className="card-row">{row.map((info, index) =>{return <CardView key={index} icon={<info.icon size='2rem' />} title={info.title} body={info.body}/>})}</div>
        })}
        </div>
        </div>
     );
}

export default Features;
