import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import TopNav from './components/TopNav';
import Home from './components/Home';
import Login from './components/Login';
import Password from './components/Password';
import AddAlias from './components/AddAlias';
import DelAlias from './components/DelAlias';
import DelAccount from './components/DelAccount';
import ListAlias from './components/ListAlias';
import FAQcontainer from './components/FAQ';
import Payment from './components/Payment';
import SetupDKIM from './components/SetupDKIM';
import Modal from './components/Modal';
import { useState } from 'react';
import ModalContextProvider from './utils/context';
import DomainUserM from './components/DomainUserM';
import PaymentSpecial from './components/PaymentSpecial';
function App() {
  var today = new Date(); 
  const year = today.getFullYear()
  const [modalInfo, setModalInfo] = useState({show: false, title: null, content: null})

  return (
    <div className="App">
      <Router>
      <header className="App-header">
        <div className='App-title'>
        <div className="App-link" ><Link style={{ textDecoration: 'none', color:'#3333c4'}} to={'/'}>XYA Mail</Link></div>
        <div className="App-link-suffix" ><div>One Mailbox</div><div>for All Your Domains</div></div>
        </div>
        <TopNav/>
      </header>
      
      <div className='App-body'>
      <ModalContextProvider>
      
        <Modal {...modalInfo} setModalInfo={setModalInfo}/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/faq' element={<FAQcontainer/>}/>
          <Route path='/signup' element={<Login signUp={true}/>}/>
          <Route path='/login' element={<Login signUp={false}/>}/>
          <Route path='/payment' element={<Payment setModalInfo={setModalInfo}/>}/>
          <Route path='/managedom' element={<AddAlias/>}/>
          <Route path='/purgedom' element={<DelAlias/>}/>
          <Route path='/listdom' element={<ListAlias/>}/>
          <Route path='/setupdkim' element={<SetupDKIM/>}/>
          <Route path='/domainuserm' element={<DomainUserM/>}/>
          <Route path='/purgeuser' element={<DelAccount/>}/>
          <Route path='/changepwd' element={<Password/>}/>
          <Route path='/azazer' element={<PaymentSpecial />}/>
        </Routes>
      </ModalContextProvider>
      </div>
      
      <div className='App-footer'>
      Copyright © XYAmail.com {year}
      </div>
      </Router>
    </div>
  );
}

export default App;
