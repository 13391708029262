import React, { useState, useEffect, useContext } from 'react'
import { Form, Input, Button, Checkbox, message } from 'antd';
import { RiUser3Line, RiLockPasswordLine } from 'react-icons/ri';
import {MdAlternateEmail} from 'react-icons/md'
import '../styles/Login.css'
import {createElementFromHTML} from '../utils/helpers.jsx'
import { modalContext } from '../utils/context';

const AddAlias = (props) => {
    const [username, setUsername] = useState(null)
    const [msg, setMsg] = useState(null)
    const [modalInfo, setModalInfo] = useContext(modalContext);

    const onFinish = data => {
        setMsg(null)
        data= {...data, submit: 'Submit'}
        const formData = new FormData();
        Object.entries(data).map(([k, v])=>{formData.append(k, v)})
        // console.log(formData)
        fetch('https://api.xyamail.com/cgi-bin/managedom.cgi', 
        {
            method: 'POST', 
            body: formData,
        })
            .then(response => {
                return response.text()
            }).catch(err => {
                console.log(err)
            }).then( r => {
                setUsername(data['user'])
                const rawHTML = createElementFromHTML(r)
                setMsg(rawHTML)
            })
    };

    useEffect(()=>{
        console.log(username, msg)
        if (username && msg) {
            setModalInfo({
                show: true, 
                title: null,
                confirm: true,
                content: <div style={{margin:'1rem'}}>
                    Username: {username}
                    {msg}
                </div>
            })
        }
    }, [msg])

    return (
        <div className="login-form-container">
            
            <Form
                name="normal_login"
                className="login-form"
                layout="inline"
                method='POST'
                onFinish={onFinish}
            >
                <a className='form-title'>Add Alias Domain</a>
            <div className='form-inputs'>
                <Form.Item
                    name="user"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username.',
                        },
                    ]}
                >
                <Input 
                prefix={<RiUser3Line className="site-form-item-icon" />} 
                placeholder="Username (without suffix)" 
                suffix={'@xyamail.com'}
                />

                </Form.Item>
                <Form.Item
                    name="pwd"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password.',
                        },
                    ]}
                >
                    <Input
                        prefix={<RiLockPasswordLine className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
 
                <Form.Item
                name="domain"
                rules={[
                    {
                        required: true,
                        message: 'Please input your new domain.',
                    },
                ]}
                >
                <Input
                    prefix={<MdAlternateEmail className="site-form-item-icon"/>}
                    // type="password"
                    placeholder="Alias Domain"
                />
                </Form.Item>
                </div>
                
                <div style={{width:'15rem', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Submit
                    </Button>
                </div>
            </Form>
            {/* <div className='response'>
            {username? <div>Username: {username}</div>: null}
            {msg? <div>{msg}</div>: null}
            </div> */}
        </div>
    );
};

export default AddAlias