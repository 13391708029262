import React, { Component } from 'react';
import PricingCard from './PricingCard';
import '../styles/Pricing.css'
import { useLocation, useNavigate } from 'react-router-dom'
import {basic, premium} from '../data/paymentDesc'

function Pricing() {
    const navigate = useNavigate()
    const clickMe=(info)=>{
      console.log(info)
      navigate('/payment', { state: info})
    }

    return ( 
    <div className="Pricing">
    <PricingCard {...basic.desc} clickMe={()=>clickMe(basic)}/>
    <PricingCard {...premium.desc} clickMe={()=>clickMe(premium)}/>
    </div>

     );
}

export default Pricing;