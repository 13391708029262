function createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    console.log(div.innerHTML)

    if (div.innerHTML.includes('<ul>') && div.childNodes[0].hasChildNodes()) {
        return <div className='response'>
            <ul>
            Alias Domains: <br/>
            {Array.from(div.childNodes[1].childNodes).map((i, index)=><li key={index}>{i.innerHTML}</li>)}
            </ul>
            </div>
    }

    return <div className={div.innerHTML.startsWith('[FAIL]') ? "response fail": 'response success'}>{div.innerHTML}</div>
}

function convertCardViewArray(info, n_cols) {
    const newArr = [];
    while(info.length) newArr.push(info.splice(0, n_cols));
    return newArr
}

function isUserNameValid(username, minLength=3, maxLength=20) {
    /* 
      Usernames can only have: 
      - Lowercase Letters (a-z) 
      - Numbers (0-9)

    */

    const res = /^[a-z0-9]+$/.exec(username);
    const valid = (!!res) && (minLength <= username.length <= maxLength);
    return valid;
  }

export {
    createElementFromHTML,
    convertCardViewArray
}