import {RiEmotionHappyLine,RiSecurePaymentLine } from "react-icons/ri";
import {BiRocket} from 'react-icons/bi'
import {GrMultiple, GrGroup, GrStatusGood} from 'react-icons/gr'

export const cardInfo =  [
        {icon: BiRocket, title: "Fast, Simple, No ADs", body: "No login, No cookie. One credential for all management needs."},
        {icon: GrMultiple, title: "Multi-Domains as Aliases", body: "One mailbox for all your domains. You can send and receive messages with any of your domain email addresses."},
        {icon: GrGroup, title: "Multi-Senders as Identifies", body: "Besides xyamail.com you can also send and receive emails with system alias simplemail.co.in."},
        {icon: RiEmotionHappyLine, title: "Good services at the best price", body: "We offer you the best price among similar products."},
        {icon: RiSecurePaymentLine, title: "Privacy first", body: "Your emails will never be scanned. Your identity will never be tracked. And your data will never be sold."},
        {icon: GrStatusGood, title: "We're Professional", body: "Our team has many years of experience on email services. We know what you want."},
    ];


