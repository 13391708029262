import React, { Component } from 'react';
import '../styles/Intro.css'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from 'react-router-dom';
function Intro() {
    return ( <div className='intro'>
        <div className='slogan' style={{borderTopLeftRadius: '3rem'}}>
        One Mailbox<br/>
        for All Your Domains
        </div>
        <div style={{width: '33%'}}>
        <AliceCarousel autoPlay autoPlayInterval="2000" 
        infinite={true} autoPlayStrategy={null} disableDotsControls={true} disableButtonsControls={true}>
        <div className="sliderimg" >Fast<br/>Simple<br/>No ADs</div>
        <div className="sliderimg">Multi-Domains</div>
        <div className="sliderimg">Best Prices</div>
        <div className="sliderimg">Privacy First</div>
        <div className="sliderimg">Professional</div>
        </AliceCarousel>
        </div >
        <Link to={'/signup'} className='get-started'>Get Started Now</Link>

    </div> );
}

export default Intro;