import React, { useState, useEffect, useContext } from 'react'
import { Form, Input, Button } from 'antd';
import { RiUser3Line, RiLockPasswordLine } from 'react-icons/ri';
import '../styles/Login.css'
import {createElementFromHTML} from '../utils/helpers.jsx'
import { modalContext } from '../utils/context';

const ListAlias = (props) => {
    const [username, setUsername] = useState(null)
    const [alias, setAlias] = useState(null)
    const [msg, setMsg] = useState(null)
    const [modalInfo, setModalInfo] = useContext(modalContext);

    const onFinish = data => {
        setMsg(null)
        console.log(data)
        data= {...data, submit: 'Submit'}
        const formData = new FormData();
        Object.entries(data).map(([k, v])=>{formData.append(k, v)})
        // console.log(formData)
        fetch('https://api.xyamail.com/cgi-bin/listdom.cgi', 
        {
            method: 'POST', 
            body: formData,
        })
            .then(response => {
                return response.text()
            }).catch(err => {
                console.log(err)
            }).then( r => {
                setUsername(data['user'])
                const rawHTML = createElementFromHTML(r)
                setMsg(rawHTML)
            }

            )
    };

    useEffect(()=>{
       
        if (username && msg) {
            setModalInfo({
                show: true, 
                title: null,
                confirm: true,
                content: <div style={{margin:'1rem'}}>
                    Username: {username}
                    {msg}
                </div>
            })
        }
    }, [msg])

    return (
        <div className="login-form-container">
            
            <Form
                name="normal_login"
                className="login-form"
                layout="inline"
                method='POST'
                onFinish={onFinish}
            >
                <a className='form-title'>List Alias Domains</a>
            <div>
                <Form.Item
                    name="user"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username.',
                        },
                    ]}
                >
                    <Input 
                    prefix={<RiUser3Line className="site-form-item-icon" />} 
                    suffix={'@xyamail.com'}
                    placeholder="Username (without suffix)" />
                </Form.Item>
                <Form.Item
                    name="pwd"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password.',
                        },
                    ]}
                >
                    <Input
                        prefix={<RiLockPasswordLine className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                </div>
                {/* <Form.Item> */}
                <div style={{width:'15rem', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Submit
                    </Button>
                </div>
                {/* </Form.Item> */}
                
            </Form>
            {/* <div className='response'>
            {username? <div>Username: {username}</div>: null}
            {alias? 
            <div>
            
            <br/>
            <div>Aliases:
            {alias.map(i=>{return <div>{i}</div>})}</div>
            </div>
            : 
            msg? <div>{msg}</div>: null
            }

            </div> */}
        </div>
    );
};

export default ListAlias