export const FAQData = [
    {
        "title": "About XYA Mail", 
        "details": 
            <ul>
                XYA Mail originated from the founder's personal project - 199903.xyz. As a free email, 199903.xyz has been running for over 2 years and we have gained valuable experience through managing the service. To better serve our users, we launched this new project xyamail.com. The main purpose of this project is to provide users with multi-domain email services at a lower price. My friends and I need a mailbox to manage emails from many different domains, and the basic version of xyamail.com does just that. For professional users, we also provide a premium version with email backup service, better security and improved deliverability.
            </ul>
    },
    {
        "title": "What's the TOS?", 
        "details": 
            <ul>
            Users may be banned for the following reasons:
                <li>Sending spam or scam.</li>
                <li>Abusing other services.</li>
                <li>Conducting illegal activities.</li>
            </ul>
        },
    {
        "title": "What's the privacy policy at XYA Mail?", 
        "details": 
            <ul>
                We may retain the following information of our users:
                <li>Registration information (E-mail address, hashed password, IP address, user agent)</li>
                <li>Cookies used to keep you logged into webmail</li>
                <li>Mail storage (Anything saved in your inbox, sent, or any other mail folders)</li>
                <li>48-72 hours of IMAP and SMTP log information</li>
                We will never sell your data to others. We do not store users' personal data and do not keep track of logs. Except webmail we don't use any cookies. There are no ADs on our website.
            </ul>
    },
    {
        "title": "Are you a registered company?", 
        "details": 
            <ul>
                Not currently, but we are planning to register a new company in Canada to run this service.
            </ul>
    },
    {
        "title": "Where are your servers deployed?", 
        "details": 
            <ul>
                US West: Los Angeles. <br/>US East: Durham.
            </ul>
    },
    {
        "title": "How can I sign up for an account?", 
        "details": 
            <ul>
                You need an invitation code to get registered. After making the payment you will get the code automatically. Please use the code to sign up by clicking the "Sign up" button.
            </ul>
    },
    {
        "title": "How to change passwords?", 
        "details": 
            <ul>
                You can change your passwords by clicking the "Change Password" button under the drop-down menu (on the right of the "Sign up" button).
            </ul>
    },
    {
        "title": "How to manage domain users?", 
        "details": 
            <ul>
                You can manage your domain users by clicking the "Domain User Management" button under the drop-down menu (on the right of the "Sign up" button).
            </ul>
    },
    {
        "title": "Besides xyamail.com, do you have other domains available?", 
        "details": 
            <ul>
                Yes. The email account on xyamail.com, will also get an alias address for same username under the domain SimpleMail.co.in
                You can send and receive emails using this alias address. You can add the alias address in Squirrelmail for sender identity. 
            </ul>
    },
    {
        "title": "Do you support personal domains?", 
        "details": 
            <ul>
            Yes. We can set up your personal domains as alias domains. A catchall address would be created to forward all domain email to your xyamail.com mailbox. You can set up domain email addresses in Squirrelmail and you will be able to send messages with your domain.
            </ul>
    },
    {
        "title": "How to set up alias domains?", 
        "details": 
            <ul>
            Please add alias domain from the management interface (Add Alias Domain). You should submit the alias domain one by one. The alias domain will be activated in the backend within minutes. Please check the status from the management interface (List Alias Domains). You should have below DNS entries to make your alias domain work as expected.
            <table>
                <tr className='table-title'><th>Hostname</th><th>Type</th><th>Value</th><th>Priority</th></tr>
                <tr><th>@</th><th>MX</th><th>mx.199903.xyz</th><th>5</th></tr>
                <tr><th>@</th><th>TXT</th><th>v=spf1 include:199903.xyz ?all</th><th></th></tr>
            </table>
            <div style={{color:'red'}}>
            All domains have DKIM enabled by default. If you want to send messages from the domain email, please add the correct DKIM key into your DNS system (see following question). Otherwise you may have email sent failed.
            </div>
            </ul>
            
    },
    {
        "title": "How to setup SPF, DKIM, DMARC for my domains?",
        "details": 
            <ul>
                First, you should have SPF setup correctly. Please add a DNS record for your domain as follows:
                <table>
                <tr className='table-title'><th>Host</th><th>Type</th><th>Value</th></tr>
                <tr><th>@</th><th>TXT</th><th>v=spf1 include:199903.xyz ?all</th></tr>
                </table>    
                Then, you could get the public key of DKIM from the button "Setup DKIM". Copy that key and add a DNS record whose value is exactly the content you copied. 
                <table>
                <tr className='table-title'><th>Host</th><th>Type</th><th>Value</th></tr>
                <tr><th>xyamail._domainkey</th><th>TXT</th><th>v=DKIM1;h=sha256;k=rsa;p=MIIBIjANBgkqhkiG9w0…</th></tr>
                </table>    
                Only when both SPF and DKIM have been setup correctly, you could setup DMARC for the domain. Add another DNS record which looks like the following.
                <table>
                <tr className='table-title'><th>Host</th><th>Type</th><th>Value</th></tr>
                <tr><th>_dmarc</th><th>TXT</th><th>v=DMARC1; p=none;</th></tr>
                </table>   
                If you were using Cloudflare as DNS hosting, the above records you have setup should look like the screenshot below:
                <br/>
                <img src={require('./faq_screenshot_1.png')} style={{margin:'1rem'}}/>
                <br/>
                Those three records are essential to the authority of the email, you should double check them carefully. After setup, you could send an email to Gmail for testing. If setup correctly, you should see the following information in Gmail's header:
                <br/>
                <img src={require('./faq_screenshot_2.png')} style={{margin:'1rem'}}/>
                <br/>
                All of them are "PASS". That's good for now.
            </ul>
    },
    {
        "title": "How many mailboxes can I get after payment?", 
        "details": 
            <ul>
                You get ONE mailbox after paying for the basic and premium plan. Mailbox refers to the email address (&#60;username&#62;@xyamail.com) that you will use for login and account management such as adding alias domains, managing domain users and DKIM setup. Emails sent to your domain users will be forwarded to this mailbox. 
            </ul>
    },
    {
        "title": "How to setup subdomain on xyamail hosting?", 
        "details": 
            <ul>
                Please follow <a href='https://notes.199903.xyz/how-to-setup-subdomain-on-xyamail-hosting' target='_blank'>this</a> tutorial.
            </ul>
    },
    {
        "title": "Detailed information of the mail server.", 
        "details": 
            <ul>
                <table>
                <tr className='table-title'><th>Service</th><th>Host</th><th>Port</th><th>Security</th></tr>
                <tr><th>Webmail</th><th>webmail.xyamail.com</th><th>443</th><th>SSL</th></tr>
                <tr><th>IMAP</th><th>mail.xyamail.com</th><th>993</th><th>SSL</th></tr>
                <tr><th>SMTP</th><th>mail.xyamail.com</th><th>587</th><th>StartTLS</th></tr>
                </table>    
                Please note the only login credential is your @xyamail.com email address and password. All your alias addresses can't be used for login. And for security reasons we don't  enable POP3 for client access.
            </ul>
    },
]