import React, { useState, useEffect, useContext } from 'react'
import { Form, Input, Button} from 'antd';
import { RiUser3Line, RiLockPasswordLine } from 'react-icons/ri';
import '../styles/Login.css'
import {createElementFromHTML} from '../utils/helpers';
import { modalContext } from '../utils/context';

const Password = (props) => {

    const [username, setUsername] = useState(null)
    const [msg, setMsg] = useState(null)
    const [modalInfo, setModalInfo] = useContext(modalContext);

    const onFinish = data => {
        
        setMsg(null)
        data= {...data, submit: 'Submit'}
        const formData = new FormData();
        Object.entries(data).map(([k, v])=>{formData.append(k, v)})

        fetch('https://api.xyamail.com/cgi-bin/changepwd.cgi', 
        {
            method: 'POST', 
            body: formData,
        })
            .then(response => {
                return response.text()
            }).catch(err => {
                console.log(err)
            }).then( r => {
                const rawHTML = createElementFromHTML(r)
                setUsername(data['email'])
                setMsg(rawHTML)
            })
    };

    useEffect(()=>{
        console.log(username, msg)
        if (username && msg) {
            setModalInfo({
                show: true, 
                title: null,
                content: <div style={{margin:'1rem'}}>
                    Email: {username}
                    {msg}
                </div>
            })
        }
    }, [msg])

    return (
        <div className="login-form-container">
            
            <Form
                name="normal_login"
                className="login-form"
                layout="inline"
                method='POST'
                onFinish={onFinish}
            >
                <a className='form-title'>Change Password</a>
            <div>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email.',
                        },
                    ]}
                >
                    <Input prefix={<RiUser3Line className="site-form-item-icon" />} placeholder="Email (with domain suffix)" />
                </Form.Item>
                <Form.Item
                    name="oldpwd"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your old password.',
                        },
                    ]}
                >
                    <Input
                        prefix={<RiLockPasswordLine className="site-form-item-icon" color='orange' />}
                        type="password"
                        placeholder="Old Password"
                    />
                </Form.Item>
 
                <Form.Item
                name="newpwd"
                rules={[
                    {
                        required: true,
                        message: 'Please input your new password.',
                    },
                ]}
                >
                <Input
                    prefix={<RiLockPasswordLine className="site-form-item-icon" color='green'/>}
                    type="password"
                    placeholder="New Password"
                />
                </Form.Item>
                
                </div>
                <div style={{width:'15rem', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Submit
                    </Button>
                </div>
            </Form>
            {/* <div className='response'>
            {username? <div>Username: {username}</div>: null}
            {msg? <div>{msg}</div>: null}
            </div> */}
        </div>
    );
};

export default Password