import React from 'react';
import '../styles/TopNav.css'
import Dropdown from './Dropdown';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom'

function TopNav() { 
    const [active, setActive] = useState(false)
    const location = useLocation();
    const navigate = useNavigate();

    const  scrollTo = async () => {
        if (location.pathname !== '/') {
            await navigate('/')
        }
        const violation = document.getElementsByClassName('Pricing') 
        window.scrollTo({
          top:violation[0].offsetTop-100,
          behavior:"smooth"
    })}

    return ( <div className='TopNav'>
        <div className="Right-nav" >
            <Link className="Nav-item" to='/faq'>FAQs</Link>
            <a className="Nav-item"  onClick={()=>scrollTo()} >Pricing</a>  
            <a className="Nav-item"  href='https://webmail.xyamail.com/squirrel/'  target='_blank' rel="noreferrer">Squirrel</a>
            <a className="Nav-item"  href='https://webmail.xyamail.com'  target='_blank' rel="noreferrer">Snappy</a>
            
            <Link to='/signup' className='Sign-up'>Sign up</Link>

            <div  className='Nav-dropdown' onClick={()=>setActive(!active)}><Dropdown active={active} setActive={setActive}/></div>
        </div>
    </div> );
}

export default TopNav;