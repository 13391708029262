import React, { Component } from 'react';
import { convertCardViewArray } from '../utils/helpers';
import { teamInfo } from "../data/teamDesc";
import CardView from './CardView';
import '../styles/Features.css'
const infoArray = convertCardViewArray(teamInfo, 2)
function AboutUs() {
    return ( 
        <div className='features'>
        <div className="features-title">
        Our Team
        </div>

        <div className="features-cardview">
        <div className="features-desc">
        We are a professional technical team with many years of experience in the IT industry. Our goal is to provide an easy-to-use email solution for domain holders. The design principles of the system are simple, practical and reliable.
        If you find any problems in use, please feel free to contact us. 
        <br/><br/>Email: cs@xyamail.com. Telegram: @XYAMail
        </div>
        {/* {infoArray.map((row, index)=>{
            return <div key={index} className="card-row">{row.map((info, index) =>{return <CardView key={index} icon={<info.icon size='2rem' />} title={info.title} body={info.body}/>})}</div>
        })} */}
        </div>
        </div>
     );
}

export default AboutUs;
