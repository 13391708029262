import React, { Component } from "react";
import CardView from "./CardView";
import "../styles/Home.css"
import Features from "./Features";
import PricingCard from "./PricingCard";
import AboutUs from "./AboutUs";
import Intro from "./Intro";
import Pricing from "./Pricing";
function Home() {    
    return ( 
    <div className="Home">
    <Intro/>
    <Features/>
    <Pricing/>
    <AboutUs/>
    </div>
    );
}

export default Home;