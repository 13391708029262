import React, { Component } from 'react';
import FAQItem from './FAQItem';
import { FAQData } from '../data/faqDesc';
import '../styles/FAQ.css'
import {FaQuestionCircle} from 'react-icons/fa'

function FAQContainer() {
    console.log(FAQData)
    return ( 
        <div className='FAQContainer'>
            <div className='FAQTitle'><FaQuestionCircle  style={{marginInline: '1rem'}}/>FAQs</div>
            {FAQData.map(data =>{return <FAQItem data={data}/>})}
        </div>
     );
}

export default FAQContainer;