import React, { useState } from 'react'
import { Form, Input, Button, Checkbox } from 'antd';
import { RiUser3Line, RiLockPasswordLine } from 'react-icons/ri';
import {MdAlternateEmail} from 'react-icons/md'
import '../styles/Login.css'
import {createElementFromHTML} from '../utils/helpers.jsx'
import { useEffect, useContext } from 'react';
import { modalContext } from '../utils/context';

const SetupDKIM = (props) => {
    const [username, setUsername] = useState(null)
    const [msg, setMsg] = useState(null)
    const [modalInfo, setModalInfo] = useContext(modalContext);
    const [checked, setChecked] = useState(false);

    const onFinish = data => {
        setMsg(null)
        data= {...data, submit: 'Submit'}
        const formData = new FormData();
        Object.entries(data).map(([k, v])=>{formData.append(k, v)})

        fetch('https://api.xyamail.com/cgi-bin/managedkim.cgi', 
        {
            method: 'POST', 
            body: formData,
        })
            .then(response => {
                return response.text()
            }).catch(err => {
                console.log(err)
            }).then( r => {
                var rawHTML = createElementFromHTML(r)
                setUsername(data['user'])
                setMsg(rawHTML)
            })
    };

    const importandNotice = ()=>{
        setModalInfo({
            show: true, 
            confirm: true,
            title: <div style={{color:'red'}}>Important Notice</div>, 
            content: 
            <>
            <div style={{margin:'1rem'}}></div>
            DKIM adds a digital signature to outgoing messages, which lets receiving servers verify the message actually came from your organization.
            When a new domain is added, our system will enable DKIM by default. So please add the DKIM public key to your DNS host ASAP otherwise you may have trouble sending emails with your domain. DNS text record has the following format:
            <table className='Modal-table'>
            <tbody>
            <tr className='table-title'><th>Hostname</th><th>Type</th><th>Value</th></tr>
            <tr><th>xyamail._domainkey</th><th>TXT</th><th>(content we returned)</th></tr>
            </tbody>
            </table>
            <div style={{margin:'1rem', color:'red'}}>If you skip this step, your outgoing emails will not pass authentication checks and will be REJECTED by other mail services.</div>
            </>})
    }


    const onChange = (e) => {
        if (e.target.checked) {
            importandNotice()
        }
        setChecked(e.target.checked);
      };

    useEffect(()=>{
        importandNotice()
        }, [])

    useEffect(()=>{
       
        if (username && msg) {
            const info = 
            setModalInfo({
                show: true, 
                confirm: true,
                content: <div style={{margin:'1rem'}}>
                    {msg.props.className.includes('fail')? null:
                    'Please copy and paste the following value (without whitespaces and tabs) into your DNS record.'}
                    {msg}
                
                </div>
            })
        }
    }, [msg])

    return (
        <div className="login-form-container">
            
            <Form
                name="normal_login"
                className="login-form"
                layout="inline"
                method='POST'
                onFinish={onFinish}
            >
                <a className='form-title'>Setup DKIM</a>
            <div className='form-inputs'>
                <Form.Item
                    name="user"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username.',
                        },
                    ]}
                >
                <Input 
                prefix={<RiUser3Line className="site-form-item-icon" />} 
                placeholder="Username (without suffix)" 
                suffix={'@xyamail.com'}
                />

                </Form.Item>
                <Form.Item
                    name="pwd"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your old password.',
                        },
                    ]}
                >
                    <Input
                        prefix={<RiLockPasswordLine className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
 
                <Form.Item
                name="domain"
                rules={[
                    {
                        required: true,
                        message: 'Please input your domain.',
                    },
                ]}
                >
                <Input
                    prefix={<MdAlternateEmail className="site-form-item-icon"/>}
                    // type="password"
                    placeholder="Alias Domain"
                />
                </Form.Item>

                <Form.Item 
                rules={[
                    {
                        required: true,
                        message: 'Please confirm.',
                    },
                ]}
                label="" 
                name="confirmation" 
                valuePropName="checked">
                <Checkbox checked={checked} onChange={onChange}>
                    I understand the impact of enabling DKIM.
                </Checkbox>
                </Form.Item>
                </div>
                <div style={{width:'15rem', display:'flex', flexDirection:'row', justifyContent:'center', 
                opacity: checked? 1:0.5,
                }}>
                    <Button 
                    disabled={!checked}
                    type="primary" 
                    htmlType="submit" 
                    className="login-form-button">
                    Get DKIM Public Key
                    </Button>
                </div>
            </Form>
            <div className='response'>
            </div>
        </div>
    );
};

export default SetupDKIM