import React, { Component } from 'react';
import '../styles/CardView.css'
function CardView(props) {
    return ( 
        <div className='card-container'>
            <div className='icon-container'>{props.icon}</div>
            <div className='content-container'>
                <div className='title-container'>{props.title}</div>
                <div className='body-container'><a>{props.body}</a></div>
            </div>

        </div>
    );
}

export default CardView;