import React, { useState, useEffect, useContext } from 'react'
import { Form, Input, Button, Checkbox, message } from 'antd';
import { RiUser3Line, RiLockPasswordLine } from 'react-icons/ri';
import {GrGroup} from 'react-icons/gr'
import '../styles/Login.css'
import { modalContext } from '../utils/context';
import {createElementFromHTML} from '../utils/helpers';

const Login = (props) => {
    const signUp = props.signUp
    const [username, setUsername] = useState(null)
    const [msg, setMsg] = useState(null)
    const [modalInfo, setModalInfo] = useContext(modalContext);

    const onFinish = data => {
        setMsg(null)
        
        if (signUp) {
            data= {...data, submit: 'Submit'}
            const formData = new FormData();
            Object.entries(data).map(([k, v])=>{formData.append(k, v)})
            fetch('https://api.xyamail.com/cgi-bin/signup.cgi', 
            {
                method: 'POST', 
                body: formData,
                
            })
                .then(response => {
                    return response.text()
                }).catch(err => {
                    console.log(err)
                }).then( r => {
                    setUsername(data['user'])
                    const rawHTML = createElementFromHTML(r)
                    setMsg(rawHTML)
            })
                    
        } else {

            data= {...data, submit: 'Submit'}
            data = {
                'js_autodetect_results': 1,
                'just_logged_in': 1,
                'smsubmit': 'Login'
            }
            const formData = new FormData();
            Object.entries(data).map(([k, v])=>{formData.append(k, v)})
            fetch('https://webmail.xyamail.com/squirrel/src/redirect.php', 
            {
                method: 'POST', 
                body: formData,
                redirect: 'follow'
            })
                .then(response => {
                    return response.text()
                }).catch(err => {
                    console.log(err)
            })    
        }
    };

    useEffect(()=>{
       
        if (username && msg) {
            setModalInfo({
                show: true, 
                title: null,
                content: <div style={{margin:'1rem'}}>
                    {msg.props.className.includes('fail')? null:
                    'Please copy and paste the following value (without whitespaces and tabs) into your DNS record.'}
                    {msg}
                </div>
            })
        }
    }, [msg])

    return (
        <div className="login-form-container">
            
            <Form
                name="normal_login"
                className="login-form"
                layout="inline"
                method='POST'
                onFinish={onFinish}
            >
                <a className='form-title'>{signUp? 'Sign Up':'Log in'}</a>
            <div>
                <Form.Item
                    name="user"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username.',
                        },
                    ]}
                >
                    <Input 
                    prefix={<RiUser3Line className="site-form-item-icon" />} 
                    suffix={'@xyamail.com'}
                    placeholder="Username (without suffix)" />
                </Form.Item>
                <Form.Item
                    name="pwd"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password.',
                        },
                    ]}
                >
                    <Input
                        prefix={<RiLockPasswordLine className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                {
                    signUp? 
                    <Form.Item
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your invitation code.',
                        },
                    ]}
                >
                    <Input
                        prefix={<GrGroup className="site-form-item-icon" />}
                        type="invitation"
                        placeholder="Invitation Code"
                    />
                </Form.Item>: null
                }
                </div>
                <div style={{width:'15rem', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Submit
                    </Button>
                </div>
            </Form>
            {/* <div className='response'>
            {username? <div>Username: {username}</div>: null}
            {msg? <div>{msg}</div>: null}
            </div> */}
        </div>
    );
};

export default Login